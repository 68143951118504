import React from "react"
import { Link, graphql, useStaticQuery, PageRendererProps } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Push from "../components/push"
import EasySection from "../components/easy_section"
import ChromeSection from "../components/chrome_section"
import DomainSection from "../components/domain_section"
import NotificationsSection from "../components/notifications_section"
import DeliverySection from "../components/delivery_section"
import ShareSection from "../components/share_section"
import DetailsSection from "../components/details_section"
import CallToActionSection from "../components/call_to_action_section"
import CallToActionButton from "../components/call_to_action_button"

class LandingPageTemplate extends React.Component {
  scrollDown() {
    var sections = document.getElementsByClassName("section");
    if (!sections || sections.length < 2) {
      return;
    }
    const y = sections[1].getBoundingClientRect().top + window.pageYOffset - 64;
    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  render() {
    const landingPage = this.props.data.markdownRemark.frontmatter
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={landingPage.title} description={landingPage.subtitle} slug={this.props.location.pathname} />
        <div className="section above-the-fold">
          <div className="container">
            <Push linkName={landingPage.linkName} />
            <div className="hero">
              <h2 style={{ whiteSpace: "pre" }}>{landingPage.title}</h2>
              <div className="subtitle">{landingPage.subtitle}</div>
              <CallToActionButton />

              <div onClick={this.scrollDown.bind(this)} className="material-icons down-arrow">keyboard_arrow_down</div>
            </div>
          </div>
        </div>
        <NotificationsSection />
        <DeliverySection />
        <ShareSection />
        <DomainSection />
        <EasySection />
        <CallToActionSection />

        {/* <DashboardSection />
        <DetailsSection />
        <DomainSection />
        <EasySection />
        <CallToActionSection /> */}
      </Layout>
    )
  }
}

export default LandingPageTemplate

export const pageQuery = graphql`
  query LandingPageBy($slug: String!) {
          site {
          siteMetadata {
          title
        }
        }
    markdownRemark(fields: {slug: {eq: $slug } }) {
          id
      excerpt(pruneLength: 160)
              html
      frontmatter {        
        title
        subtitle
        linkName
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`
